import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import Dashboard from './components/Dashboard';
import AudienceDetail from './components/AudienceDetail'; 
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import FeaturesSection from './components/FeaturesSection';
import PricingSection from './components/PricingSection';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import ProtectedRoute from './routes/ProtectedRoute';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {

  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        {/* Navbar remains at the top */}
        <NavBar />

        {/* Main content area grows to push the footer down */}
        <main className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SignedIn>
                    <Navigate to="/dashboard" />
                  </SignedIn>
                  <SignedOut>
                    <div>
                      <HeroSection />
                      <FeaturesSection />
                      <PricingSection />
                    </div>
                  </SignedOut>
                </>
              }
            />

            {/* Protected Dashboard route */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            {/* Terms and Conditions */}
            <Route path="/terms" element={<TermsAndConditions />} />

            {/* Privacy Policy */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Audience Detail with protected route */}
            <Route
              path="/audience/:audience"
              element={
                <ProtectedRoute>
                  <AudienceDetail />
                </ProtectedRoute>
              }
            />

            {/* 404 Not Found Page */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        {/* Footer stays at the bottom */}
        {/* <Footer /> */}
        {/* console.log('Footer loaded') */}
      </div>
    </BrowserRouter>
  );
}

export default App;

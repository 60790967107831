import React, { useState } from 'react';

const Search = ({ subreddits, onSearchResults }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const searchSubreddits = async (event) => {
    if (event) event.preventDefault();
    if (!query) return;
  
    setLoading(true);
    setError('');
  
    try {
      // Format the subreddits to match backend expectations
      const formattedSubreddits = subreddits.map((sub) => ({
        subreddit: sub.subreddit_name, // Use subreddit_name as the key
      }));
  
  
      // Make the request
      const response = await fetch(`${baseUrl}/v1/search-within-subreddits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subreddits: formattedSubreddits, query }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch subreddit posts');
      }
  
      const results = await response.json();
  
      if (onSearchResults) {
        onSearchResults(results);
      }
    } catch (error) {
      console.error('Error during search:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="mb-4">
      <form onSubmit={searchSubreddits} className="flex items-center">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search Reddit"
          className="border p-2 rounded-l w-full text-black"
        />
        <button
          type="submit"
          className="bg-gradient-to-r from-pink-500 via-indigo-600 to-pink-500 text-white p-2 rounded-r"
        >
          Search
        </button>
      </form>
      {loading && <p className="text-gray-500 mt-2">Searching...</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default Search;

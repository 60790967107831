import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Search from './Search';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
const AudienceDetail = () => {
  const { audience } = useParams(); // Audience title from the route
  const location = useLocation();
  const navigate = useNavigate(); // Navigate hook for back button functionality

  const subreddits = location.state?.subreddits || JSON.parse(localStorage.getItem(audience)) || [];
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control side menu visibility

  const handleSearchResults = (results) => {
    // Sort results by `created_utc` in descending order (newest first)
    const sortedResults = results.sort((a, b) => b.created_utc - a.created_utc);
    setSearchResults(sortedResults);
    setIsMenuOpen(true); // Open the side menu when results are received
  };
  

  return (
    <div className="relative flex flex-col min-h-screen bg-[#1A2238] text-white">
      {/* Back Button */}
      <div className="container mx-auto p-4">
        <button
          onClick={() => navigate(-1)} // Navigate back to the previous page
          className="flex items-center bg-gray-800 text-white px-4 py-2 rounded shadow hover:bg-gray-700"
        >
          <ChevronLeftIcon className="h-5 w-5" /> {/* Chevron Icon */}
          <span className="ml-1">{audience}</span> {/* Audience Name */}
        </button>
      </div>


      {/* Search Bar */}
      <div className="container mx-auto p-4">
        <Search subreddits={subreddits} onSearchResults={handleSearchResults} />
      </div>

      {/* Main Subreddit Content */}
      <div className="container mx-auto p-4 bg-white shadow-md rounded-lg text-black">
        <h1 className="text-2xl font-bold mb-4">Subreddits for {audience}</h1>
        <ul>
          {subreddits.map((subreddit, index) => (
            <li key={index} className="mb-6 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={subreddit.icon_img || 'https://via.placeholder.com/40'}
                  alt={`${subreddit.subreddit_name_prefixed} icon`}
                  className="h-10 w-10 rounded-full mr-4"
                />
                <div>
                  <h2 className="text-lg font-bold">{subreddit.subreddit_name_prefixed}</h2>
                  <p className="text-sm">Subscribers: {subreddit.subscribers}</p>
                </div>
              </div>
              <a
                href={`https://www.reddit.com/r/${subreddit.subreddit_name_prefixed}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-pink-500"
              >
                Visit Subreddit
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Side Menu with Overlay for Results */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsMenuOpen(false)} // Close menu when overlay is clicked
          ></div>

          {/* Results Menu */}
          <div className="fixed top-0 right-0 h-full w-1/2 bg-gray-50 shadow-lg z-50 transition-transform transform">
            <div className="flex items-center justify-between p-4 bg-gray-200">
              <h2 className="text-xl font-semibold text-black">Results</h2>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="flex items-center text-black hover:text-red-500"
              >
                <span className="mr-2 text-lg">X</span>
                Close
              </button>
            </div>
            <div
              className="p-4 overflow-y-auto h-full"
              style={{ maxHeight: 'calc(100vh - 64px)' }}
            >
              <ul>
                {searchResults.map((result, index) => {
                  // Convert the `created_utc` to a human-readable date
                  const postDate = new Date(result.created_utc * 1000);

                  // Get the current date for comparison
                  const now = new Date();

                  // Determine the display format
                  let formattedDate;
                  if (postDate.toDateString() === now.toDateString()) {
                    formattedDate = postDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                  } else {
                    formattedDate = postDate.toLocaleDateString(); // Show date
                  }
                  return (
                    <li
                      key={index}
                      className="relative bg-white p-4 rounded shadow-md mb-4 border"
                    >
                      <a
                        href={`https://www.reddit.com${result.permalink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lg font-bold text-black hover:text-blue-600 mb-1 block"
                      >
                        {result.title}
                      </a>
                      <p className="text-sm text-gray-600 mb-2 line-clamp-2">
                        {result.selftext || 'No additional text available.'}
                      </p>
                      <div className="text-sm text-gray-500 italic mt-2">
                        {result.subreddit_name_prefixed}
                      </div>
                      {/* Timestamp at the bottom-right corner */}
                      <div className="absolute bottom-2 right-2 text-xs text-gray-400">
                        {formattedDate}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AudienceDetail;
